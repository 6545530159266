/* You can add global styles to this file, and also import other style files */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #fafbfd;
  color: #212529;
}

.mat-toolbar-single-row {
  background-color: #03223f;
  color: #ffffff;
  box-shadow: 0 0 2px rgb(4 34 63 / 20%);;
  justify-content: space-between;

  .wallet {
    font-weight: 400;
    font-size: 16px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  max-width: 1200px;
  padding: 32px;
  margin: 0 auto;
  height: calc(100% - 64px);
}

.mat-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button {
  font-weight: 400;
}

.mat-snack-bar-container {
  background-color: #fff;

  &.error {
    color: #FC4D70;
  }

  &.success {
    color: #1CCC92;
  }

  &.info {
    color: #3b50e0;
  }

  button {
    color: #212529;
  }
}

.color-danger {
  color: #FC4D70;
}
